import PropTypes from 'prop-types';
import {
  createContext,
  useContext,
  useReducer,
} from 'react';
import Sentinel from '../components/GSR/sentinel';

export const STAGE_CALIBRATION = 'calibration';
export const STAGE_TESTING = 'testing';
export const NEXT_STEP = 'nextStep';
export const PREV_STEP = 'prevStep';
export const FINISH_CALIBRATION = 'finishCalibration';
export const FINISH_TESTING = 'finishTesting';
export const RESTART = 'restart';
export const RESTART_TESTING = 'restartTesting';

const MAX_STEP_OF_STAGE_CALIBRATION = 2;

const initialState = {
  stage: STAGE_CALIBRATION,
  step: 0,
  sentinel: Sentinel,
};

const AppStateDispatchContext = createContext(null);
const AppStateContext = createContext(initialState);

export function useAppState() {
  return useContext(AppStateContext);
}

export function useAppStateDispatch() {
  return useContext(AppStateDispatchContext);
}

function stateReducer(state, action) {
  const { type, data } = action;
  const { stage, step, sentinel } = state;

  sentinel.sendCustomData({
    action: 'stateChange',
    type,
    stage,
    step,
  });

  switch (type) {
    case NEXT_STEP: {
      if (stage === STAGE_CALIBRATION
          && step === MAX_STEP_OF_STAGE_CALIBRATION) {
        return {
          ...state,
          step: 0,
          stage: STAGE_TESTING,
        };
      }
      return {
        ...state,
        step: step + 1,
        data: {
          ...state.data,
          ...data,
        },
      };
    }
    case PREV_STEP: {
      return {
        ...state,
        step: step - 1,
      };
    }
    case FINISH_TESTING: {
      return {
        ...state,
        step: 4,
        data: {
          ...state.data,
          ...data,
        },
      };
    }
    case RESTART: {
      return initialState;
    }
    case RESTART_TESTING: {
      return {
        ...state,
        stage: STAGE_TESTING,
        step: 0,
      };
    }
    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }
}

export function AppStateProvider({ children }) {
  const [state, dispatch] = useReducer(
    stateReducer,
    initialState,
  );

  return (
    <AppStateContext.Provider value={state}>
      <AppStateDispatchContext.Provider value={dispatch}>
        {children}
      </AppStateDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

AppStateProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
