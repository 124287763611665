const Requirements = () => (
  <details className="dropdown dropdown-top">
    <summary
      id="minimum-system-requirements"
      className="btn btn-ghost text-slate-500
        hover:bg-transparent hover:text-base-content"
      data-dropdown-toggle="dropdown"
      type="button"
    >
      Minimum System Requirements
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-4"
        fill="currentColor"
        stroke="currentColor"
        viewBox="0 0 511.735 511.735"
      >
        <path
          d="M263.535,248.453c-4.16-4.16-10.88-4.16-15.04,0L3.054,
            493.787c-4.053,4.267-3.947,10.987,0.213,15.04 c4.16,3.947,10.667,
            3.947,14.827,0l237.867-237.76l237.76,237.76c4.267,4.053,10.987,
            3.947,15.04-0.213c3.947-4.16,3.947-10.667,0-14.827L263.535,248.453z"
        />
        <path
          d="M18.201,263.493l237.76-237.76l237.76,237.76c4.267,4.053,10.987,
            3.947,15.04-0.213c3.947-4.16,3.947-10.667,0-14.827 L263.535,
            3.12c-4.16-4.16-10.88-4.16-15.04,0L3.054,248.453c-4.053,
            4.267-3.947,10.987,0.213,15.04 C7.534,267.547,14.041,267.547,18.201,
            263.493z"
        />
      </svg>
    </summary>
    <div
      className="dropdown-content card bg-base-100 rounded-box b-1 z-[300]
        w-[600px] ml-[-200px] shadow-xl text-xs overflow-auto p-8"
    >
        <div className="text-center font-medium mb-4">
          Minimum System Requirements
        </div>
        <ul className="list-disc space-y-2">
          <li>
            <b>Used in a desktop environment</b>: Microsoft Windows, Mac OS, or
            a desktop Linux (any distribution with a GUI) based PC or laptop
            computer. Don&apos;t use a tablet, mobile device, smart TV,
            gaming platform, in-car device, etc., as it might lead to
            unexpected results.
          </li>
          <li>
            <b>Using a traditional pointing device:</b> computer mouse,
            trackball, trackpoint, trackpad, touchpad, fundamentally any device
            that moves a mouse cursor on the screen in a continuous fashion.
            Notably, using a touchscreen won&apos;t work well with RDD
            because it doesn&apos;t provide enough data points.
          </li>
          <li>
            <b>Using a single pointing device</b> consistently throughout the
            demo session. E.g., switching from a regular mouse to a touchpad or
            vice versa during the session could easily trigger a false
            alert. It&apos;s ok if the &quot;attacker&quot; uses another
            type of pointing device, as long as it also falls into one of
            the above categories. In fact, this makes tampering detection
            easier. (Note: This limitation applies only to the demo version,
            and it won’t be an issue in practice if someone needs to switch
            later on.)
          </li>
          <li>
            <b>Used with one of the more common web browsers</b>: Mozilla
            Firefox, any Chromium based browser (notably Google Chrome,
            Microsoft Edge and Opera), or Safari. Niche browsers, e.g., that
            make extensive use of the keyboard for navigation, are not supported
            (e.g. Lynx, qute, or standard browsers with keyboard-supporting
            extensions like Vimium).
          </li>
          <li>
            <b>The protocol must be followed</b>, especially regarding the
            minimum amount of time spent with moving the pointing device. Lack
            of enough data results in loss of detection accuracy. Also make
            sure that the &quot;attacker&quot; actually takes control of the
            mouse pointer during the demo. Just telling the &quot;victim
            &quot; what to do is not enough to trigger the alert.
          </li>
        </ul>
    </div>
  </details>
);

export default Requirements;
