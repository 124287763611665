import { useCallback } from 'react';
import { useAppState } from '../contexts/AppStateProvider';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';

const Step = () => {
  const appState = useAppState();
  const { step } = appState;

  const getCurrentStep = useCallback(() => {
    switch (step) {
      case 0: return <Step1/>;
      case 1: return <Step2/>;
      case 2: return <Step3/>;
      case 3: return <Step4/>;
      case 4: return <Step5/>;
      default: return null;
    }
  }, [step]);

  return getCurrentStep();
};

export default Step;
