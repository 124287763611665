import LogoLight from '../../images/graboxy-light.svg';
import SubMenu from './subMenu';
import {
  RESTART,
  RESTART_TESTING,
  STAGE_CALIBRATION,
  STAGE_TESTING,
  useAppState,
  useAppStateDispatch,
} from '../../contexts/AppStateProvider';

const restartIcon = (<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 20 20"
  fill="currentColor"
  className="w-5 h-5"
>
  <path
    fillRule="evenodd"
    d="M15.312 11.424a5.5 5.5 0 0 1-9.201
    2.466l-.312-.311h2.433a.75.75 0 0 0 0-1.5H3.989a.75.75
    0 0 0-.75.75v4.242a.75.75 0 0 0 1.5 0v-2.43l.31.31a7 7 0
    0 0 11.712-3.138.75.75 0 0 0-1.449-.39Zm1.23-3.723a.75.75
    0 0 0 .219-.53V2.929a.75.75 0 0 0-1.5 0V5.36l-.31-.31A7
    7 0 0 0 3.239 8.188a.75.75 0 1 0 1.448.389A5.5 5.5 0 0 1
    13.89 6.11l.311.31h-2.432a.75.75 0 0 0 0 1.5h4.243a.75.75
    0 0 0 .53-.219Z"
    clipRule="evenodd"
  />
</svg>);

const Menu = () => {
  const appState = useAppState();
  const { stage, sentinel } = appState;
  const dispatch = useAppStateDispatch();

  const restart = () => {
    sentinel.reset(true);
    dispatch({ type: RESTART });
  };

  const restartTesting = () => {
    sentinel.reset();
    dispatch({ type: RESTART_TESTING });
  };

  return (
    <div className="w-1/4 flex justify-start items-start flex-col">
      <div className="mb-5 pb-5">
        <div>
          <img src={LogoLight} />
        </div>
        <p>
          Remote Access Detection
          {' '}
          <span className="demo">Demo</span>
        </p>
      </div>
      <div className='w-full mr-12'>
        <div className="mb-4">
          <button
            onClick={restart}
            className="btn btn-primary"
          >
            Calibrate new User
            {restartIcon}
          </button>
        </div>
        <div>
          <SubMenu
            id="menu-calibration"
            caption="Calibrate"
            stage={STAGE_CALIBRATION}
            numberOfSteps={3}
          />
        </div>
        <div className="mt-8">
          <div className="mb-4">
            <button
              onClick={restartTesting}
              className={`btn ${stage !== STAGE_TESTING
                ? 'disabled:bg-white btn-outline'
                : 'btn-primary'}`
              }
              disabled={stage !== STAGE_TESTING}
            >
              New Test
              {restartIcon}
            </button>
          </div>
          <SubMenu
            id="menu-testing"
            caption="Test"
            stage={STAGE_TESTING}
            numberOfSteps={5}
          />
        </div>
      </div>
      <p className="text-slate-400 mt-auto text-center">
        © {new Date().getFullYear()}, Cursor Insight
      </p>
      <p className="text-slate-400">
        v0.8
      </p>
    </div>
  );
};

export default Menu;
