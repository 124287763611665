import { MemoryRouter, Routes, Route } from 'react-router-dom';

import Scene from './components/Scene';
import CopyInfo from './components/CopyInfo';

import Main from './pages/Main';

const App = () => (
  <Scene>
    <MemoryRouter>
      <Routes>
        <Route path="/" element={<Main />} />
      </Routes>
    </MemoryRouter>
    <CopyInfo/>
  </Scene>
);

export default App;
