import { AppStateProvider } from '../../contexts/AppStateProvider';
import Step from '../../steps';
import Menu from '../../components/Menu';
import Browser from '../../components/Browser';
import JoyRide from '../../components/JoyRide';

const Main = () => (
  <AppStateProvider>
    <JoyRide />
    <Menu />
    <Browser>
      <Step />
    </Browser>
  </AppStateProvider>
);

export default Main;
