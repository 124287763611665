import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  NEXT_STEP,
  PREV_STEP,
  useAppState,
  useAppStateDispatch,
  STAGE_CALIBRATION,
} from '../../contexts/AppStateProvider';

const Step3 = () => {
  const dispatch = useAppStateDispatch();
  const appState = useAppState();

  const {
    stage,
    data,
    sentinel,
  } = appState;
  const {
    newRecipientToggle,
    newRecipientName,
    newRecipientAccount,
    existingRecipientAccount,
    amount,
    sourceAccount,
  } = data.formData;

  const cancel = () => dispatch({ type: PREV_STEP });
  const submit = () => {
    if (stage === STAGE_CALIBRATION) {
      sentinel.finishReferenceDataCollection();
    } else {
      sentinel.finishQuestionedDataCollection();
    }
    dispatch({ type: NEXT_STEP });
  };

  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const onEventsCollected = ({ progress: p }) => {
      setProgress(p);
    };
    sentinel.on('eventsCollected', onEventsCollected);
    sentinel.emitEventCount();
    return () => {
      sentinel.off('eventsCollected', onEventsCollected);
    };
  }, []);

  return (
    <>
      <div className="card w-2/4 bg-base-100 shadow-xl mb-16">
        <div className="card-body">
          <h2 className="card-title">Confirm Transfer</h2>
          <div className="overflow-x-auto">
            <table className="table">
              <tbody>
                <tr>
                  <td>From</td>
                  <td className="text-right">{sourceAccount}</td>
                </tr>
                <tr>
                  <td>To</td>
                  <td className="text-right">
                    {newRecipientToggle
                      ? `${newRecipientName} - ${newRecipientAccount}`
                      : existingRecipientAccount}
                  </td>
                </tr>
                <tr>
                  <td>Amount</td>
                  <td className="text-right">{amount}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-actions justify-end">
            <button
              className="btn btn-primary"
              onClick={submit}
              disabled={progress < 100}
            >
              Confirm
            </button>
            <button className="btn btn-secondary" onClick={cancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

Step3.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      amount: PropTypes.string,
      sourceAccount: PropTypes.string,
    }),
  }),
};

export default Step3;
