import { useEffect, useState } from 'react';
import { useAppState } from '../../contexts/AppStateProvider';

const GSR = () => {
  const state = useAppState();
  const { sentinel } = state;

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const onEventsCollected = ({ progress: p }) => {
      setProgress(p);
    };
    sentinel.on('eventsCollected', onEventsCollected);
    return () => {
      sentinel.off('eventsCollected', onEventsCollected);
    };
  }, []);

  return (
    <div id="gsr-progress">
      <label className="form-control mb-8">
        <div className="label">
          <span className="label-text text-slate-500">
            Data collection progress
          </span>
        </div>
        <progress
          id="progress-bar"
          className={`progress w-56 ${progress >= 100
            ? 'progress-primary'
            : 'progress-secondary'
          }`}
          value={progress}
          max={100}
        />
      </label>
    </div>
  );
};

export default GSR;
