import {
  useAppStateDispatch,
  NEXT_STEP,
  useAppState,
} from '../../contexts/AppStateProvider';

const Step1 = () => {
  const dispatch = useAppStateDispatch();
  const state = useAppState();
  const { stage, sentinel } = state;

  const startDemo = () => {
    sentinel.start();
    dispatch({ type: NEXT_STEP });
  };

  return (
    <div className="flex-1 flex flex-col justify-around p-32">
      <div className="text-lg font-bold">
        Welcome to the DemoBank website!
      </div>
      <button
        className="btn btn-primary mx-auto"
        id={`start-${stage}`}
        onClick={startDemo}
      >
        Make a mock bank transfer
      </button>
    </div>
  );
};

export default Step1;
