import PropTypes from 'prop-types';
import { Step, Stepper } from '@cimpress/react-components';
import {
  STAGE_CALIBRATION,
  useAppState,
} from '../../contexts/AppStateProvider';

const steps = [
  'Start',
  'Transfer Details',
  'Confirm Transfer',
  'Graboxy Fraud Detection',
  'Session Completed',
];

export default function SubMenu({
  id,
  stage,
  caption,
  numberOfSteps,
}) {
  const state = useAppState();
  const { stage: activeStage, step: activeStep } = state;
  const isCurrent = stage === activeStage;
  const otherStep = activeStage === STAGE_CALIBRATION ? -1 : 4;

  return (
    <div className="mb-4" id={id}>
      <div className="font-bold text-lg">{caption}</div>
      <Stepper
        activeStep={isCurrent ? activeStep : otherStep}
        vertical
        style={{ height: `${numberOfSteps * 50}px` }}
        className="ml-8"
      >
        {steps.filter((item, idx) => idx < numberOfSteps).map((item, idx) => (
          <Step key={idx}>{item}</Step>
        ))}
      </Stepper>
    </div>
  );
}

SubMenu.propTypes = {
  id: PropTypes.string.isRequired,
  stage: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  numberOfSteps: PropTypes.number.isRequired,
};
